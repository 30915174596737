<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="合同列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        :isSelection="true"
        show-page
        :loading="loading"
        ref="purchaseContract"
        @select-change="handleSelect"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >新增
          </dd-button>
          <dd-button
            type="primary"
            icon="update"
            size="warning"
            @click="handleSaleMaintenance"
            >关联销售合同维护
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>

    <dd-popup
      v-if="pageDetailVisible"
      width="78%"
      height="80%"
      showBtn
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="pageDetailVisible"
      title-name="详情"
      @popup-close="handleClose"
    >
      <pageDetail
        ref="pageDetail"
        :pageProp="pageProp"
        slot="main"
      ></pageDetail>
    </dd-popup>

    <!-- 新增合同 -->
    <dd-popup
      v-if="AddEditContractVisible"
      width="40%"
      height="55%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="AddEditContractVisible"
      :title-name="addEditContactTitle"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit"
        >提交</dd-button
      >
      <addEditContract
        ref="addEditContract"
        :pageProp="pageProp"
        slot="main"
      ></addEditContract>
    </dd-popup>

    <!-- 关联信息维护 -->
    <dd-popup
      v-if="maintenanceVisible"
      width="78%"
      height="80%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="maintenanceVisible"
      title-name="子单据维护"
      @popup-close="handleClose"
    >
      <maintenance
        ref="maintenance"
        :pageProp="pageProp"
        slot="main"
      ></maintenance>
    </dd-popup>

    <!-- 关联销售合同维护 -->
    <dd-popup
      v-if="saleMaintenanceVisible"
      width="78%"
      height="80%"
      showBtn
      :saveBtn="false"
      :submitBtn="true"
      :show="saleMaintenanceVisible"
      title-name="关联销售合同维护"
      @popup-close="handleClose"
      @popup-submit="handleSaleMaintenanceSubmit"
    >
      <saleMaintenance
        ref="saleMaintenance"
        :salePageProp="salePageProp"
        slot="main"
      ></saleMaintenance>
    </dd-popup>
  </div>
</template>



<script>
import { putApi, postApi, delApi } from "@/api/request";
import pageDetail from "./components/pageDetail.vue";
import addEditContract from "./components/addEditContract.vue";
import maintenance from "./components/maintenance.vue";
import saleMaintenance from "./components/saleMaintenance.vue";

export default {
  name: "procurementContract",
  $dynamicRoute: {
    name: "金蝶采购合同",
    path: "/contract/procurementContract/index",
  },
  components: { pageDetail, addEditContract, maintenance, saleMaintenance },
  data() {
    return {
      formList: [
        {
          label: "合同编码",
          value: "fnumber",
          inputType: "text",
          placeholderText: "请输入合同编码进行搜索",
        },
        {
          label: "合同名称",
          value: "fname",
          inputType: "text",
          placeholderText: "请输入合同名称进行搜索",
        },
        {
          label: "工程项目",
          value: "fprojectName",
          inputType: "text",
          placeholderText: "请输入工程项目进行搜索",
        },
        {
          label: "合同状态",
          value: "fcontractstatusName",
          inputType: "text",
          placeholderText: "请输入合同状态进行搜索",
        },
        {
          label: "合同类型",
          value: "fcontracttypeName",
          inputType: "text",
          placeholderText: "请输入合同类型进行搜索",
        },
        {
          label: "甲方",
          value: "fpartaName",
          inputType: "text",
          placeholderText: "请输入甲方进行搜索",
        },
        {
          label: "乙方",
          value: "fpartbName",
          inputType: "text",
          placeholderText: "请输入乙方进行搜索",
        },
        {
          label: "合同属性",
          value: "cfproperty",
          inputType: "text",
          placeholderText: "请输入合同属性进行搜索",
        },
        {
          label: "采购子类型",
          value: "cfproperty1",
          inputType: "text",
          placeholderText: "请输入采购子类型进行搜索",
        },
        {
          label: "关联类型",
          value: "correlationType",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择关联类型进行搜索",
        },
        {},
      ],
      pageDetailVisible: false,
      saveBtn: false,
      loading: false,
      pageProp: {},
      salePageProp: {},
      drawerVisible: false,
      tableHeight: null,
      tableColumns: [
        {
          title: "合同编码",
          key: "fnumber",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "工程项目",
          key: "fprojectName",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "合同状态",
          key: "fcontractstatusName",
          align: "center",
          overflow: true,
        },
        {
          title: "合同类型",
          key: "procurementTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "合同属性",
          key: "fcontractkindName",
          align: "center",
          overflow: true,
        },
        {
          title: "合同总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 110,
        },
        {
          title: "累计变更金额",
          key: "bgAmount",
          align: "center",
          overflow: true,
          width: 110,
        },
        {
          title: "应结算金额",
          key: "settleAmount",
          align: "center",
          overflow: true,
          width: 110,
        },
        {
          title: "变更后总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计结算金额",
          key: "settleFoftax",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "未结算金额",
          key: "wjsAmount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计实付金额",
          key: "paymentForiginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "未付金额",
          key: "wfAmount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计开票金额",
          key: "invoiceAllotmentAmount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "已开票未付款金额",
          key: "ykwfkAmount",
          align: "center",
          overflow: true,
          width: 160,
        },
        {
          title: "开始日期",
          key: "fbegindate",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "结束日期",
          key: "ffinishdate",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "经办日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "甲方",
          key: "fpartaName",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "乙方",
          key: "fpartbName",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "合同属性",
          key: "cfproperty",
          align: "center",
          overflow: true,
        },
        {
          title: "采购子类型",
          key: "cfproperty1",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "关联类型",
          key: "correlationTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 200,
          event: [
            {
              state: "default",
              label: "编辑",
              width: 100,
              type: "warning",
              method: (row) => {
                this.handleEditRow(row);
              },
              rules: (row) => {
                let show = false;
                if (row.syncTag === 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "删除",
              type: "danger",
              width: 100,
              method: (row) => {
                this.handleDelete(row);
              },
              rules: (row) => {
                let show = false;
                if (row.syncTag === 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "子单据维护",
              width: 100,
              type: "primary",
              method: (row) => {
                this.handleMaintenance(row);
              },
              rules: (row) => {
                let show = false;
                if (row.syncTag === 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "查看详情",
              width: 100,
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      columnsList: [],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      tableData: [],
      searchData: {},
      AddEditContractVisible: false,
      addEditContactTitle: "",
      selectData: [],
      maintenanceVisible: false,
      saleMaintenanceVisible: false,
    };
  },
  created() {
    this.columnsList = [...this.tableColumns];
    this.getDataType();
    this.getTableList();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
  },
  methods: {
    handleDetail(row) {
      this.pageProp.pageType = "detail";
      this.pageProp.id = row.id;
      this.pageDetailVisible = true;
    },
    getTableList() {
      postApi(
        `/contract/contractpurchaseinfo/page/${this.current}/${this.size}`,
        { ...this.searchData }
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleSearchEvent(e) {
      this.searchData = e;
      this.current = 1;
      this.getTableList();
    },
    handleClose() {
      this.pageDetailVisible = false;
      this.AddEditContractVisible = false;
      this.maintenanceVisible = false;
      this.saleMaintenanceVisible = false;
      this.$refs.purchaseContract.$refs.baseTable.clearSelection();
      this.pageProp.data = null;
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableList();
    },
    handleSelect(e) {
      this.selectData = e;
    },
    handleAdd() {
      this.AddEditContractVisible = true;
      this.addEditContactTitle = "新增";
      this.pageProp.pageType = "add";
    },
    handleSubmit() {
      let flag = false;
      this.$refs.addEditContract.$refs.addPopForm.$refs.addPopForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      let params = {
        ...this.$refs.addEditContract.$refs.addPopForm.formData,
        id: this.pageProp.data? this.pageProp.data.id : null
      };
      
      if (params.id && params.id != null && params.id != "") {
        putApi(`/contract/contractpurchaseinfo`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            this.$message.warning("编辑失败，请稍后重试");
          }
          this.AddEditContractVisible = false;
          this.getTableList();
        });
      } else {
        postApi(`/contract/contractpurchaseinfo/save`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("保存成功");
          } else {
            this.$message.warning("保存失败，请稍后重试");
          }
          this.AddEditContractVisible = false;
          this.getTableList();
        });
      }
    },
    handleMaintenance(row) {
      this.maintenanceVisible = true;
      this.pageProp.purchaseInfoId = row.id;
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/contract/contractpurchaseinfo/${row.id}`).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("删除成功");
          } else {
            this.$message.warning("删除失败，请稍后重试");
          }
          this.getTableList();
        });
      });
    },
    handleSaleMaintenance() {
      if (this.selectData.length != 1) {
        this.$message.warning("请选择一条数据！");
        return false;
      }
      this.saleMaintenanceVisible = true;
      this.salePageProp.purchaseInfoId = this.selectData[0].id;
      this.salePageProp.ftotalamount = this.selectData[0].ftotalamount;
    },
    handleSaleMaintenanceSubmit() {
      let flag = false;
      this.$refs.saleMaintenance.$refs.assignForm_list.$refs.assignForm_list.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }

      let params = {
        ...this.$refs.saleMaintenance.$refs.assignForm_list.formData,
        id: this.salePageProp.purchaseInfoId,
      };

      this.$refs.saleMaintenance.contarctList.forEach((item) => {
        if (params.saleContractCode != "") {
          if (item.code == params.saleContractCode) {
            params.saleContractId = item.id;
          }
        } else {
          params.saleContractId = "";
        }
      });
      postApi(`/contract/contractpurchaseinfo/update/sale`, params).then(
        (res) => {
          if (res.data.code == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.warning("操作失败，请稍后重试");
          }
          this.saleMaintenanceVisible = false;
          this.selectData = [];
          this.$refs.purchaseContract.$refs.baseTable.clearSelection();
          this.getTableList();
        }
      );
    },
    handleEditRow(row) {
      this.AddEditContractVisible = true;
      this.pageProp.pageType = "edit";
      this.pageProp.data = row;
      this.addEditContactTitle = "编辑";
    },
    getDataType() {
      postApi(`/contract/dicttype/list/all`, { code: "PC-DB-GLLX" }).then(
        (res) => {
          if (res.data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "correlationType") {
                item.optionsList = res.data.data;
              }
            });
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .container .tabs_card111 .el-tabs__item {
  width: 17% !important;
}
</style>