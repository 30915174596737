<template>
  <div class="main-container">
    <dd-card class="table-tab" v-if="
      resolveType == 'childTask' && treeRowData.nodeType == 'PRJ-RWJHJDLX-GJD'
    ">
      <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
        <el-tab-pane v-for="val in tabList" :key="val.value" :label="val.label" :name="val.value"></el-tab-pane>
      </el-tabs>
    </dd-card>
    <dd-form v-if="activeName == 'ptjd'" :form-config="formBasicList" ref="addPopForm">
    </dd-form>
    <div v-if="activeName == 'bzjd'" class="bottom-box">
      <div class="left-box">
        <dd-card title="已选择标准节点">
          <dd-table :columns="leftTableColumns" :data="leftTableData" border ref="dLeftTableRef" :isSelection="false">
          </dd-table>
        </dd-card>
      </div>
      <div class="right-box">
        <div class="head_wrap">
          <dd-card title="简易子任务模板" spacer>
            <dd-form :form-config="formList" :flex="2" ref="ddSearchForm"></dd-form>
          </dd-card>
        </div>
        <dd-card title="选择模板下的节点并指定负责人" :height="tableHeight">
          <dd-table :columns="tableColumns" :data="tableData" border ref="workTable"
            @select-change="handleSelectionChange($event, 2)">
            <template #table-btn>
              <dd-button type="primary" size="medium" @click="handleSubmit">提交</dd-button>
            </template>
          </dd-table>
        </dd-card>
      </div>
      <dd-popup width="50%" height="50%" showBtn inPopup :saveBtn="true" :cancelBtn="true" :show="editPopupVisible"
        title-name="编辑" @popup-close="editPopupVisible = false"
        @popup-save="handleEditPopSave('addPopForm', 'baseForm')">
        <model-node-edit-pop v-if="editPopupVisible" :rowData="rowData" slot="main" ref="editPop">
        </model-node-edit-pop>
      </dd-popup>
    </div>
    <div v-if="activeName == 'bzgzbtz'" class="third-box">
      <dd-card title="">
        <dd-table :columns="TableColumns_bzgzbtz" :data="TableData_bzgzbtz" :tree-props="{ children: 'children' }"
          node-key="id" :expand-row-keys="treeData" border ref="dLeftTable_bzgzbtzRef" :isSelection="false">
          <template #table-btn>
            <dd-button type="primary" size="medium" icon="add" @click="addStandardMasterNode()">增加标准主节点</dd-button>
            <dd-button type="primary" size="medium" icon="add" @click="addWorkPackage()">增加工作包</dd-button>
          </template>
        </dd-table>
      </dd-card>
    </div>

    <dd-popup width="70%" height="50%" class="standardMasterNodeClass" showBtn inPopup :saveBtn="true" :cancelBtn="true"
      :show="standardMasterNode" title-name="新增标准主节点" @popup-close="standardMasterNode = false"
      @popup-save="standardMasterNodeSave('standardMasterNodeRef', 'standardMasterNodeForm')">
      <standardMasterNodePage v-if="standardMasterNode" slot="main" ref="standardMasterNodeRef"
        formRef="standardMasterNodeRef" :treeRowData="treeRowData"></standardMasterNodePage>
    </dd-popup>
    <dd-popup width="70%" height="50%" showBtn inPopup :saveBtn="true" :cancelBtn="true" :show="workPackage"
      title-name="新增工作包" @popup-close="workPackage = false"
      @popup-save="workPackageSave('workPackageRef', 'workPackageForm')">
      <workPackagePage v-if="workPackage" slot="main" ref="workPackageRef" :rowId="rowId" formRef="workPackageRef"
        :treeRowData="treeRowData"></workPackagePage>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, delApi } from "@/api/request";
import standardMasterNodePage from './standardMasterNode.vue'
import workPackagePage from './workPackage.vue'
import ModelNodeEditPop from "./ModelNodeEditPop.vue";
import $ from "jquery";
export default {
  name: "add-pop",
  props: {
    resolveType: "",
    dictType: [],
    treeRowData: null,
    poptitleName: "",
    rowId: '',
  },
  components: {
    ModelNodeEditPop, standardMasterNodePage, workPackagePage
  },
  data() {
    return {
      treeData: [],
      standardMasterNode: false, // 标准主节点
      workPackage: false, // 工作包
      tableHeight: null,
      activeName: "ptjd",
      tabList: [
        {
          label: "普通节点",
          value: "ptjd",
        },
        {
          label: "简易模式标准节点",
          value: "bzjd",
        },
        {
          label: "标准工作包调整",
          value: "bzgzbtz",
        },
      ],
      formBzgzbtzList: [
        {
          label: "选择本任务书中的标准主节点",
          value: "templateId",
          inputType: "select",
          placeholderText: "请选择模板名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          change: this.changeTemplateId,
        },
      ],
      TableData_bzgzbtz: [],
      TableColumns_bzgzbtz: [
        {
          title: "节点编号",
          key: "nodeCode",
          align: "center",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "业务类型",
          key: "businessTypeName",
          align: "center",
        },
        {
          title: "生产分管部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "部门",
          key: "nodeDeptBranchName",
          align: "center",
        },
        {
          title: "负责人",
          key: "nodeUser",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "删除",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleDelete(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (
                  row.nodeType == "PRJ-RWJHJDLX-GZBBZ" && row.addNodeType == 1
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ]
        },
      ],
      formBasicList: [
        {
          label: "上级节点",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "nodeName",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择上级节点",
          rules: [
            {
              required: true,
              message: "请选择上级节点",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点名称",
          value: "nodeName",
          inputType: "text",
          placeholderText: "请输入节点名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入节点名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点类型",
          value: "nodeType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择节点类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择节点类型",
              trigger: "blur",
            },
          ],
        },
      ],
      formList: [
        {
          label: "模板名称",
          value: "templateId",
          inputType: "select",
          placeholderText: "请选择模板名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          change: this.modelChange,
          rules: [
                        {
                            required: true,
                            message: "请选择模板名称",
                            trigger: "blur",
                        },
                    ],
          },
        {
                    label: "业务类型",
                    value: "businessTypeId",
                    inputType: "cascader",
                    optionsList: [],
                    defaultValue: "",
                    prop: {
                        value: "id",
                        label: "name",
                        checkStrictly: true,
                        emitPath: false,
                    },
                    showAllSelcet: false,
                    placeholderText: "请选择业务类型",
                    rules: [
                        {
                            required: true,
                            message: "请选择业务类型",
                            trigger: "blur",
                        },
                    ],
                },
      ],
      tableColumns: [
        {
          title: "节点",
          key: "name",
          align: "center",
        },
        {
          title: "生产负责部门",
          key: "receiveDeptName",
          align: "center",
        },
        {
          title: "部门",
          key: "childDepName",
          align: "center",
        },
        {
          title: "负责人",
          key: "fullName",
          align: "center",
        },
        {
          title: "核算组",
          key: "accounting",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
            },
          ],
        },
      ],
      leftTableColumns: [
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.addNodeType != 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      leftTableData: [],
      rowData: null,
      multipleSelection: [],
      leftMultipleSelection: [],
      editPopupVisible: false,
      modelId: null,
    };
  },
  created() {
    this.getBusinessType();
  },
  mounted() {

    if(this.resolveType == 'wbs'){
      this.formBasicList.push(
        {
          label: "计划开始时间",
          value: "planStarttime",
          placeholderText: "请选择计划开始时间",
          inputType: "dateStr",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: '',
        },
        {
          label: "计划结束时间",
          value: "planEndtime",
          placeholderText: "请选择计划结束时间",
          inputType: "dateStr",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: '',
        },
      )
    }

    this.getDataType();
    if (this.dictType) {
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.optionsList = this.dictType;
        }
      });
    }
    if (this.treeRowData != null) {
      this.formBasicList.forEach((item) => {
        if (this.poptitleName == "新增") {
          if (item.value == "parentId") {
            item.defaultValue = this.treeRowData.id;
          }
        } else {
          item.defaultValue = this.treeRowData[item.value];
        }
      });
    }
  },
  methods: {
    workPackageSave(ref, formRef) {
      let flag = false;
      this.$refs[ref].$refs[formRef].$refs[formRef].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if(flag) {
        if (this.$refs[ref].selectionData.length == 0) {
          this.$message.warning('请至少勾选一条数据');
          return false;
        }
        this.$refs[ref].selectionData.map((item) => {
          item.name = item.nodeWorkName
        })
        postApi(`/project/subplannode/subplan/addstandpackage/${this.treeRowData.subplanId}/${this.$refs[ref].id}`, this.$refs[ref].selectionData).then(res => {
          if(res.data.code == 0) {
            this.$message.success('操作成功')
          }
          else {
            this.$message.success('操作失败，请稍后重试')
          }
          this.workPackage = false;
          this.$refs[ref].$refs.workTable.$refs.baseTable.clearSelection();
          this.getBzgzbTableData()
        })
      }
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    filterTreeData(data) {
      this.treeData = [];
      if (data?.length > 0) {
        data.forEach(item => {
          this.treeData.push(item.id)
        })
      }
    },
    getBzgzbTableData() {
      getApi(`/project/subplannode/tree/${this.treeRowData.subplanId}`).then(res => {
        if (res.data.code == 0) {
          this.TableData_bzgzbtz = res.data.data[0].children;
          this.filterTreeData(res.data.data[0].children)
        }
      })
    },
    standardMasterNodeSave(ref, formRef) {
      let flag = false;
      this.$refs[ref].$refs[formRef].$refs[formRef].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (flag) {
        if (this.$refs[ref].selectionData.length == 0) {
          this.$message.warning('请至少勾选一条数据');
          return false;
        }
        let isNext = false;
        this.$refs[ref].selectionData.forEach((item) => {
          if (item.accounting == null) {
            isNext = true;
          }
        });
        if (isNext) {
          this.$message.warning("所选节点核算组不能为空，请编辑操作");
          return false;
        }

        postApi(`/project/subplannode/subplan/addstandnode/${this.treeRowData.subplanId}/${this.$refs[ref].$refs[formRef].formData.businessTypeId}`,
          this.$refs[ref].selectionData).then(res => {
            if (res.data.code == 0) {
              this.$message.success('操作成功')
            }
            else {
              this.$message.success('操作失败，请稍后重试')
            }
            this.$refs[ref].$refs.workTable.$refs.baseTable.clearSelection();
            this.standardMasterNode = false;
            this.getBzgzbTableData()
          })
      }
      
    },
    addWorkPackage() {
      this.workPackage = true;
    },
    addStandardMasterNode() {
      this.standardMasterNode = true;
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(2);
      });
      this.activeName = tab.name;
      if (this.activeName == "bzjd") {
        $(".popup-box").attr("style", "width: 70%;height: 80% !important");
        $(".popup-btn").hide();
        this.getLeftTableData();
      }
      else if (this.activeName == 'bzgzbtz') {
        this.getBzgzbTableData();
        $(".popup-box").attr("style", "width: 70%;height: 80% !important");
        $(".popup-btn").hide();
      } else {
        $(".popup-box").attr("style", "width: 30%;height: 50% !important");
        $(".popup-btn").show();
      }
    },
    // 获取数据字典类型
    getDataType() {
      // 节点类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-RWJHJDLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            data.data.forEach((item, index) => {
              if (
                item.code == "PRJ-RWJHJDLX-GJD" ||
                item.code == "PRJ-RWJHJDLX-ZRW" ||
                item.code == "PRJ-RWJHJDLX-ZHJDBZ" ||
                item.code == "PRJ-RWJHJDLX-GZBBZ"
              ) {
                data.data.splice(index, 1);
              }
            });
            let options = {
              value: "nodeType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      getApi(`/project/prjwbsbasetemplate/list/zrw`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "templateId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    modelChange(e) {
      if (e) {
        this.modelId = e;
        this.getTableData();
      } else {
        this.tableData = [];
      }
    },
    // 表格多选事件
    handleSelectionChange(val, type) {
      if (type == 1) {
        this.leftMultipleSelection = val;
      } else {
        this.multipleSelection = val;
      }
    },
    // 获取表格列表数据
    getTableData() {
      this.loading = true;
      getApi(
        `/project/subplannode/unselected/standard/node/${this.modelId}/${this.treeRowData.subplanId}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          data.data.forEach((item) => {
            item.receiveDeptId = this.treeRowData.nodeDeptId;
            item.receiveDeptName = this.treeRowData.nodeDeptName;

            item.receiveSubDeptId = null;
            item.childDepName = null;

            item.leaderId = null;
            item.fullName = null;

            item.areaAccountingId = null;
            item.accounting = null;
          });
          this.tableData = data.data;
        }
      });
    },
    loopData(data) {
            if (data && data.length) {
                data.forEach((item) => {
                    if (item?.children && item?.children?.length > 0) {
                        item.disabled = true;
                        this.loopData(item.children);
                    }
                });
            }
            return data;
        },
    // 业务类型
    getBusinessType() {
            getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    let arr = this.loopData(data.data);
                    let options = {
                        value: "businessTypeId",
                        optionsList: arr,
                    };
                    this.setOPtionsList(this.formList, options);
                }
            });

        },
    getLeftTableData() {
      this.leftLoading = true;
      getApi(
        `/project/subplannode/selected/standard/node/${this.treeRowData.subplanId}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.leftLoading = false;
          this.leftTableData = data.data;
        }
      });
    },
    handleRowEditor(row) {
      this.rowData = row;
      this.editPopupVisible = true;
    },
    handleEditPopSave(formName, formNameBase) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.editPop.formDataObj;

      if (flag) {
        this.tableData.forEach((item) => {
          if (item.id == this.rowData.id) {
            item.receiveSubDeptId = params.receiveSubDeptId;
            item.childDepName = params.childDepName;

            item.leaderId = params.leaderId;
            item.fullName = params.fullName;

            item.areaAccountingId = params.areaAccountingId;
            item.accounting = params.accounting;

            item.receiveDeptId = params.receiveDeptId;
            item.receiveDeptName = params.receiveDeptName;
          }
        });
        this.editPopupVisible = false;
      }
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      postApi(`/project/subplannode/del/node/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getLeftTableData();
        this.getTableData();
        if (this.activeName == 'bzgzbtz') {
          this.getBzgzbTableData()
        }
      });
    },
    //提交
    handleSubmit() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请选择节点");
        return;
      }

      if (!this.$refs.ddSearchForm.formData.businessTypeId) {
        this.$message.warning("请选择业务类型");
        return;
      }
      let isNext = false;
      this.multipleSelection.forEach((item) => {
        if (item.accounting == null) {
          isNext = true;
        }
      });
      if (isNext) {
        this.$message.warning("所选节点核算组不能为空，请编辑操作");
        return;
      }
      let params = this.multipleSelection;
      postApi(
        `/project/subplannode/subplan/readdnode/${this.treeRowData.subplanId}/${this.$refs.ddSearchForm.formData.businessTypeId}`,
        params
      ).then((res) => {
        if (res.data && res.data.code == 0) {
          this.getLeftTableData();
          this.getTableData();
          this.$refs.workTable.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .card-contant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;

  .el-tabs {
    width: 100%;
  }
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__nav {
  width: auto !important;
}

/deep/ .el-tabs__item {
  width: auto !important;
}
/deep/ .el-input__inner {
    padding: 0  0  0 25px  !important;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 61px);

  /deep/ .el-table__header-wrapper .gutter {
    height: 40px !important;
  }

  .left-box {
    width: 23%;

    /deep/ .has-gutter {
      .el-table-column--selection {
        .cell {
          display: none;
        }
      }
    }
  }

  .right-box {
    width: 77%;
  }

  /deep/ .search-form-input {
    width: 33% !important;
  }

  /deep/ .search-form {
    width: 100%;
  }
}

.third-box {
  height: calc(100% - 61px);

  /deep/ .el-table__header-wrapper .gutter {
    height: 40px !important;
  }

  .third-box-table {
    padding: 0 20px;
    display: flex;

    .btn_box {
      display: flex;
      align-items: center;
    }
  }
}
</style>
