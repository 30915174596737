<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" :labelWidth="'190px'" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>

        <dd-card title="合同汇总列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border ref="dTableRef" @select-change="handleSelectionChange" :isSelection="true" show-page :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="export" size="medium" @click="handleExport">导出
                    </dd-button>
                </template>
                <template #table-btn-right>
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>

        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>
    </div>
</template>

<script>
import { postApi, loadApi } from '@/api/request';
import { downloadBinaryFile } from "@/utils/index";
export default {
    name: 'contractSummaryBrowsing',
    $dynamicRoute: {
        name: "合同汇总浏览",
        path: "/contract/contractSummaryBrowsing/index",
    },
    data() {
        return {
            loading: false,
            formList: [
                {
                    label: "年度",
                    value: "year",
                    inputType: "text",
                    placeholderText:
                        "请输入采购年度",
                },
                {
                    label: "合同状态",
                    value: "status",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    defaultValue: "",
                    placeholderText: "请选择合同状态",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                },
                {
                    label: "经办日期区间",
                    value: "applyDateArr",
                    inputType: "date",
                    dateType: 'daterange',
                    placeholderText:
                        "请选择经办日期区间",
                },
                {
                    label: "供应商（乙方）",
                    value: "partyBCompany",
                    inputType: "text",
                    placeholderText:
                        "请输入供应商（乙方）",
                },
                {
                    label: "合同编号",
                    value: "code",
                    inputType: "text",
                    placeholderText:
                        "请输入合同编号",
                },
                {
                    label: "合同名称",
                    value: "name",
                    inputType: "text",
                    placeholderText:
                        "请输入合同名称",
                },
                {
                    label: "项目编号",
                    value: "prjCode",
                    inputType: "text",
                    placeholderText:
                        "请输入项目编号",
                },
                {
                    label: "项目名称",
                    value: "prjName",
                    inputType: "text",
                    placeholderText:
                        "请输入项目名称",
                },
                {
                    label: "合同分类",
                    value: "classify",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    defaultValue: "",
                    placeholderText: "请选择合同分类",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                },
              {
                label: "合同类型",
                value: "type",
                inputType: "select",
                optionsList: [],
                filterable: true,
                defaultValue: "",
                placeholderText: "请选择合同类型",
                optionsListConfig: {
                  name: "name",
                  code: "code",
                },
              },{}
            ],
            tableColumns: [
                {
                    title: "采购年度",
                    key: "year",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同编号",
                    key: "code",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "合同名称",
                    key: "name",
                    align: "center",
                    overflow: true,
                    width: 190,
                },
                {
                    title: "合同状态",
                    key: "status",
                    align: "center",
                    overflow: true,
                }, {
                    title: "合同类型",
                    key: "type",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同分类",
                    key: "classifyName",
                    align: "center",
                    overflow: true,
                }, {
                    title: "合同总金额",
                    key: "initAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "变更后合同总金额",
                    key: "finallyAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },{
                    title: "应结算金额",
                    key: "settleAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "累计结算金额",
                    key: "fseletleamount",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "未结算金额",
                    key: "unsettlebalance",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
                {
                    title: "累计实付金额",
                    key: "frealamount",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "未付金额",
                    key: "unpaidamount",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
                {
                    title: "累计开票金额",
                    key: "fextax",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "已开票未付款金额",
                    key: "fextaxedUnpaid",
                    align: "center",
                    overflow: true,
                    width: 150,
                },
                {
                    title: "关联项目编号",
                    key: "prjCode",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "关联项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                    width: 160,
                }, {
                    title: "合同甲方",
                    key: "partyAcompany",
                    align: "center",
                    overflow: true,
                    width: 160,
                },
                {
                    title: "合同乙方",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                    width: 160,
                }, 
                {
                    title: "经办日期",
                    key: "applyDate",
                    align: "center",
                    overflow: true,
                    width: 160,
                },
                {
                    title: "关联销售合同",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 160,
                    event: [
                        {
                        type: "primary",
                        method: (row) => {
                            this.handleRowHref(row);
                        },
                        href: (row) => {
                            return (
                            row.saleContractCode
                            );
                        },
                        },
                    ],
                },
                {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 160,
                    event: [
                        {
                        type: "primary",
                        method: (row) => {
                            this.handleRowHref(row);
                        },
                        href: (row) => {
                            return (
                            row.saleContractCode
                            );
                        },
                        },
                    ],
                },
            ],
            drawerVisible: false,
            tableHeight: null,
            tableData: [],
            searchObj: {},
            selectTable: [],
            columnsList: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getDataType();
        this.getTableList();
    },
    mounted() {
        this.columnsList = [...this.tableColumns];
        this.$nextTick(() => {
                this.tableHeight = this.autoTableHeight(0);
            });
    },
    methods: {
        getTableList() {
            postApi(`/contract/selfestablished/procurement/sum/page/${this.current}/${this.size}`, this.searchObj).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records
                    this.total = res.data.data.total;
                }
            })
        },
        handleSearchEvent(e) {
            this.current = 1;
            this.searchObj = e;
            this.getTableList();
        },
        handleSelectionChange(e) { // 表格 select 状态
        this.selectTable = e;
        },
        handleExport() {
            // 
            // if(this.selectTable.length == 0) {
            //     this.$message.warning('请选择数据');
            //     return false;
            // }
            let params = {
                ...this.searchObj,
                ids:this.selectTable.map(item => { return item.id})
            }
            
            loadApi(`/contract/selfestablished/procurement/sum/export`, params).then(res => {
                let { data } = res;
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
                this.multipleSelection = [];
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success('导出成功')
            })
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableList();
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: "PC-GLZT-STATUS"}).then(res => {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'classify') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
            postApi(`/contract/dicttype/list/all`, {code: "PC-COLLECT-STATUS"}).then(res => {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'status') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
          postApi(`/contract/dicttype/list/all`, {code: "PC-CCONTRACTTYPE-STATUS"}).then(res => {
            if(res.data.code == 0) {
              this.formList.forEach(item => {
                if(item.value == 'type') {
                  item.optionsList = res.data.data;
                }
              })
            }
          })
            
        },
        handleRowHref(row) {
            console.log('row', row)
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
    }
}
</script>

<style lang="less" scoped></style>