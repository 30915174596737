<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" labelWidth="180px" ref="addPopForm" formRef="addPopForm">
    </dd-form>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";

export default {
  name: "addEditSettlement",
  props: ['pageSettleProp'],
  data() {
    return {
      formBasicList: [
        {
          label: "单据编号",
          value: "fnumber",
          inputType: "text",
          placeholderText: "保存后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "单据名称",
          value: "fname",
          inputType: "text",
          placeholderText: "请输入单据名称",
          rules: [
            {
              required: true,
              message: "请输入单据名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "本期金额",
          value: "foriginalamount",
          inputType: "text",
          placeholderText: "请输入本期金额",
          rules: [
            {
              required: true,
              message: "请输入本期金额",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "期间",
          value: "fperiodid",
          inputType: "select",
          placeholderText: "请选择期间",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            name: "number",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择期间",
              trigger: "blur",
            },
          ],
        },
        {
          label: "业务日期",
          value: "fbizdate",
          inputType: "dateStr",
          placeholderText: "请选择业务日期",
          dateType: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          rules: [
            {
              required: true,
              message: "请选择业务日期",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getperiodList();
    if(this.pageSettleProp.pageType == 'edit'){
      setTimeout((res)=>{
        this.initFormData();
      },500);
    }
  },
  mounted() {},
  methods: {
    getperiodList() {
      postApi(`/admin/sysperiod/list`).then((res) => {
        if (res.data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "fperiodid") {
              item.optionsList = res.data.data;
            }
          });
        }
      });
    },
    initFormData(){
      this.$refs.addPopForm.formData = {...this.pageSettleProp.data}
    }
  },
};
</script>

<style>
</style>