<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer :showOpen="true" :ExpandAndCollapse="ExpandAndCollapse">
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="合同列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" ref="selfBuiltContract" @select-change="handleSelect" border
                :isSelection="true" show-page :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="add" size="medium" @click="handleAdd">新增自建合同
                    </dd-button>
                    <dd-button type="warning" icon="update" size="medium" @click="handleEdit">合同金额变更
                    </dd-button>
                    <!-- <dd-button type="primary" size="medium" @click="handleMaintenance">关联信息维护
                    </dd-button> -->
                    <dd-button type="success" size="medium" @click="handleLock">合同信息锁定
                    </dd-button>
                    <dd-button type="primary" size="medium" @click="handleCorrect">合同信息更正
                    </dd-button>
                    <dd-button type="danger" size="medium" icon="delete" @click="handleDeleteselfBuiltContract">删除
                    </dd-button>
                    <dd-button type="info" size="medium" @click="handleExport">导出
                    </dd-button>
                </template>
                <template #table-btn-right>
                    <!-- <dd-button type="primary" size="medium" @click="handleImport">导入
                    </dd-button> -->
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>

        <dd-popup v-if="AddEditVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="AddEditVisible" :title-name="addEditTitle" @popup-close="handleClose">
            <dd-button v-if="pageProp.pageType != 'change'" slot="button" size="medium" type="primary"
                @click="handleSubmit(0, 'addEditDetails')">暂存</dd-button>
            <dd-button slot="button" size="medium" type="primary"
                @click="handleSubmit(1, 'addEditDetails')">提交</dd-button>
            <addEditDetails ref="addEditDetails" :pageProp="pageProp" slot="main"></addEditDetails>
        </dd-popup>

        <dd-popup v-if="maintenanceVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="maintenanceVisible" title-name="关联信息维护" @popup-close="handleClose">
            <maintenance ref="maintenance" :pageProp="pageProp" slot="main"></maintenance>
        </dd-popup>

        <dd-popup v-if="contractDetailVisible" width="78%" height="80%" showBtn :saveBtn="false" :submitBtn="true"
            :show="contractDetailVisible" title-name="合同更正信息" @popup-close="handleClose" 
            @popup-submit="handleCorrectSub">
            <correctDetails ref="correctDetails" :pageProp="pageProp" slot="main"></correctDetails>
        </dd-popup>

        <dd-popup v-if="detailVisible" width="78%" height="80%" showBtn :saveBtn="false" :submitBtn="false"
            :show="detailVisible" title-name="查看详情" @popup-close="handleClose">
            <contractDetail ref="detailVisible" :pageProp="pageProp" slot="main"></contractDetail>
        </dd-popup>
    </div>
</template>

<script>
import addEditDetails from './components/addEditDetails.vue'
import maintenance from './components/maintenance.vue'
import contractDetail from './components/contractDetail.vue'
import { delApi, postApi, putApi,loadApi } from '@/api/request';
import correctDetails from './components/correctDetails.vue';
import { downloadBinaryFile } from "@/utils/index";
export default {
    name: 'selfBuiltContract',
    $dynamicRoute: {
        name: "自建合同管理",
        path: "/contract/selfBuiltContract/index",
    },
    components: { addEditDetails, maintenance, contractDetail,correctDetails },
    data() {
        return {
            detailVisible: false,
            loading: false,
            drawerVisible: false,
            componentKey: 0,
            maintenanceVisible: false,
            AddEditVisible: false,
            contractDetailVisible: false,
            pageProp: {},
            addEditTitle: '',
            columnsList: [],
            tableData: [],
            tableColumns: [
                {
                    title: "采购年度",
                    key: "procurementYear",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建采购合同编号",
                    key: "code",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "自建采购合同名称",
                    key: "name",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购合同类型",
                    key: "procurementTypeName",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "乙方单位",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购总金额",
                    key: "procurementInitialAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "采购合同变更后总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                    width: 170,
                },
                {
                    title: "应结算金额",
                    key: "settleAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "采购累计结算金额",
                    key: "procurementSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "未结算金额",
                    key: "unSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "金蝶累计开票金额",
                    key: "procurementinvoiceAmount",
                    align: "center",
                    overflow: true,
                    width: 160,
                }, {
                    title: "金蝶累计实付金额",
                    key: "procurementPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "未付金额",
                    key: "unPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 130,
                }, {
                    title: "金蝶关联金额",
                    key: "purchaseAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "未关联金额",
                    key: "unPurchaseAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购未付占采购应付比例(%)",
                    key: "unPayRatio",
                    align: "center",
                    overflow: true,
                    width: 200,
                }, {
                    title: "采购合同额占销售合同比例(%)",
                    key: "puchaseRatio",
                    align: "center",
                    overflow: true,
                    width: 220,
                }, {
                    title: "关联项目编号",
                    key: "prjCode",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "关联项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "关联销售合同名称",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "应付外协约定比例(%)",
                    key: "payableOutsourceScale",
                    align: "center",
                    overflow: true,
                    width: 170,
                }, {
                    title: "扣除费用",
                    key: "deductExpenses",
                    align: "center",
                    overflow: true,
                }, {
                    title: "扣除费用详情备注",
                    key: "detailRemark",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "计算外协合同总金额",
                    key: "outsourceAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "销售合同额",
                    key: "saleContractAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "销售合同价格形式",
                    key: "cfnature",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "销售合同结算金额",
                    key: "saleContractSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "销售回款",
                    key: "saleContractRefund",
                    align: "center",
                    overflow: true,
                }, {
                    title: "销售回款比例",
                    key: "saleContractRefundRatio",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "制单人",
                    key: "applierName",
                    align: "center",
                    overflow: true,
                }, {
                    title: "状态",
                    key: "statusName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 200,
                    event: [
                        {
                            state: "default",
                            label: "编辑",
                            width: 100,
                            type: "warning",
                            method: (row) => {
                                this.handleEditRow(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-ZJCG-STATUS-ZC") {
                                    show = true;
                                } else {
                                    show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "删除",
                            type: 'danger',
                            width: 100,
                            method: (row) => {
                                this.handleDelete(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-ZJCG-STATUS-ZC") {
                                    show = true;
                                } else {
                                    show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "关联信息维护",
                            width: 100,
                            type: "primary",
                            method: (row) => {
                                this.handleMaintenance(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-ZJCG-STATUS-GLZ" || row.status == "PC-ZJCG-STATUS-KSD") {
                                    show = true;
                                } else {
                                    show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "查看详情",
                            width: 100,
                            method: (row) => {
                                this.handleRowDetail(row);
                            },
                        },
                    ],
                },
            ],
            formList: [
                {
                    label: "合同编号",
                    value: "code",
                    inputType: "text",
                    placeholderText:
                        "请输入合同编号进行搜索",
                },
                {
                    label: "合同名称",
                    value: "name",
                    inputType: "text",
                    placeholderText:
                        "请输入合同名称进行搜索",
                },
                {
                    label: "乙方单位",
                    value: "partyBCompany",
                    inputType: "text",
                    placeholderText:
                        "请输入乙方单位进行搜索",
                },
                {
                    label: "项目编号",
                    value: "prjCode",
                    inputType: "text",
                    placeholderText:
                        "请输入项目编号进行搜索",
                },
                {
                    label: "项目名称",
                    value: "prjName",
                    inputType: "text",
                    placeholderText:
                        "请输入项目名称进行搜索",
                },
                {
                    label: "状态",
                    value: "status",
                    inputType: "select",
                    optionsList: [],
                    defaultValue: "",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请选择状态进行搜索",
                },
                {},
            ],
            selectData: [],
            seachData: {},
            tableHeight: null,
            saveBtn: false,
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getDataType()
        this.getTableData()
    },
    mounted() {
        this.columnsList = [...this.tableColumns];
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });

    },
    methods: {
        ExpandAndCollapse(val) {
            if (val) {
                this.tableHeight = 'calc(100% - 45px)';
            }
            else {
                this.tableHeight = `calc(100% - ${Math.ceil(this.formList.length / 4) * 51 + 45}px)`;
            }
        },
        getTableData() {
            postApi(`/contract/selfestablished/procurement/page/${this.current}/${this.size}`, { ...this.seachData }).then(res => {
                if (res.data.code == 0) {
                    this.total = res.data.data.total;
                    this.tableData = res.data.data.records;
                }
              this.clearTableSelection();
            })
        },
        handleSearchEvent(e) {
            this.current = 1;
            this.seachData = e;
            this.getTableData();
        },
        handleAdd() {
            this.AddEditVisible = true;
            this.pageProp.pageType = 'add'
            this.pageProp.id = null
            this.addEditTitle = '新增'
        },
        handleSelect(e) {
            this.selectData = e;
        },
        handleEdit() {
            if (this.selectData.length != 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            if (this.selectData[0].status == 'PC-ZJCG-STATUS-GLZ' || this.selectData[0].status == 'PC-ZJCG-STATUS-KSD') {
                this.AddEditVisible = true;
                this.pageProp.pageType = 'change'
                this.pageProp.id = this.selectData[0].id;
                this.addEditTitle = '合同金额变更'
            }
            else {
                this.$message.warning('只可操作状态为关联中或可锁定的数据')
                return false
            }

        },
        handleCorrect(){
            if (this.selectData.length != 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            if (this.selectData[0].status == 'PC-ZJCG-STATUS-GLZ' || this.selectData[0].status == 'PC-ZJCG-STATUS-KSD' 
                || this.selectData[0].status == 'PC-ZJCG-STATUS-YSD') {
                this.pageProp.id = this.selectData[0].id;
                this.pageProp.procurementFinallyAmount = this.selectData[0].procurementFinallyAmount;
                this.contractDetailVisible = true;
            }else{
                this.$message.warning('只可操作状态为关联中，可锁定，已锁定的数据')
                return false
            }
        },
        handleCorrectSub(){
            let flag = false;
            this.$refs.correctDetails.$refs.assignForm_list.$refs.assignForm_list.validate((valid) => {
                if (valid) {
                    flag = true
                }
            })
            if (!flag) { return false };

            
            let procurementInitialAmount = this.$refs.correctDetails.$refs.assignForm_list.formData.procurementInitialAmount;
            let deductExpenses = this.$refs.correctDetails.$refs.assignForm_list.formData.deductExpenses;
            if ((procurementInitialAmount && procurementInitialAmount != '' && !/^-?\d+(\.\d+)?$/.test(procurementInitialAmount))
                || (deductExpenses && deductExpenses != '' && !/^-?\d+(\.\d+)?$/.test(deductExpenses))) {
                return
            }
            let params = {};
            params = { 
                ...this.$refs.correctDetails.$refs.assignForm_list.formData, 
                ...this.$refs.correctDetails.$refs.assignForm_reference.formData,
                id: this.pageProp.id, 
            };  
            this.$refs.correctDetails.contarctList.forEach(item => {
                if(params.saleContractCode != ''){
                    if (item.code == params.saleContractCode) {
                        params.prjId = item.prjId;
                        params.saleContractId = item.id
                    }
                }else{
                        params.prjId = '';
                        params.saleContractId = ''
                }
            });

            if(this.selectData[0].procurementYear != this.$refs.correctDetails.$refs.assignForm_list.formData.procurementYear){
                    this.$confirm(`采购年度发生变化将导致合同编号发生变化，是否确认修改？`, "提示", {
                    confirmButtonClass: "btnsearch",
                    confirmButtonText: "确认",
                    cancelButtonClass: "btnreset",
                    cancelButtonText: "取消",
                    showClose: true,
                    type: "warning",
                }).then(() => {
                    postApi(`/contract/selfestablished/procurement/correct`, params).then(res => {
                        if (res.data.code == 0) {
                            this.$message.success('操作成功')
                        }
                        else {
                            this.$message.warning('操作失败，请稍后重试')
                        }
                        this.contractDetailVisible = false;
                        this.selectData = [];
                        this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
                        this.getTableData()
                    })
                });
            }else{
                    postApi(`/contract/selfestablished/procurement/correct`, params).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.contractDetailVisible = false;
                    this.selectData = [];
                    this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
                    this.getTableData()
            })
            }
        },
        handleExport(){   
            let params = {
                ...this.seachData,
                ids:this.selectData.map(item => { return item.id})
            }

            loadApi(`/contract/selfestablished/procurement/self/contract/export`, params).then(res => {
                let { data } = res;
                this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success('导出成功')
            })
        },
        handleEditRow(row) {
            this.AddEditVisible = true;
            this.pageProp.pageType = 'edit';
            this.pageProp.id = row.id
            this.addEditTitle = '编辑'
        },
        handleMaintenance(row) {
            this.maintenanceVisible = true;
            this.pageProp.pageType = 'relation';
            this.pageProp.procurementFinallyAmount = row.procurementFinallyAmount
            this.pageProp.id = row.id;
        },
        // handleDetail() {},
        handleRowDetail(row) {
            this.pageProp.pageType = 'detail';
            this.pageProp.id = row.id
            this.detailVisible = true;
        },
        handleLock() {
            if (this.selectData.length == 0) {
                this.$message.warning('请至少勾选一条数据')
                return false
            }
            let isLock = false;
            this.selectData.forEach(item => {
                if (item.status != 'PC-ZJCG-STATUS-KSD') {
                    isLock = true
                }
            })
            if (isLock) {
                this.$message.warning('只可操作状态为可锁定的数据')
                return false
            }
            let params = this.selectData.map(item => { return item.id })
            postApi(`/contract/selfestablished/procurement/lock`, { ids: params }).then(res => {
                if (res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败，请稍后重试')
                }
                this.getTableData();
                this.selectData = [];
                this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
            })
        },
        handleDeleteselfBuiltContract(){
            if (this.selectData.length != 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            this.$confirm(`确认删除该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(() => {
                delApi(`/contract/selfestablished/procurement/delete?id=${this.selectData[0].id}`).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.getTableData();
                    this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
                })
            });
        },
        handleImport() { },
        handleDelete(row) {
            this.$confirm(`确认删除该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(() => {
                delApi(`/contract/selfestablished/procurement/delete?id=${row.id}`).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.getTableData()
                })
            });

        },
        handleClose() {
            this.AddEditVisible = false;
            this.maintenanceVisible = false;
            this.contractDetailVisible = false;
            this.detailVisible = false;
            this.selectData = [];
            this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableData();
        },
        handleSubmit(type, ref) {
            if (ref == 'addEditDetails' && (this.pageProp.pageType != 'change')) {
                let flag = false;
                if (type == 1) {
                    this.$refs.addEditDetails.$refs.assignForm_list.$refs.assignForm_list.validate((valid) => {
                        if (valid) {
                            flag = true
                        }
                    })
                    if (!flag) { return false };
                }

                let procurementInitialAmount = this.$refs.addEditDetails.$refs.assignForm_list.formData.procurementInitialAmount;
                let deductExpenses = this.$refs.addEditDetails.$refs.assignForm_list.formData.deductExpenses;
                if ((procurementInitialAmount && procurementInitialAmount != '' && !/^-?\d+(\.\d+)?$/.test(procurementInitialAmount))
                    || (deductExpenses && deductExpenses != '' && !/^-?\d+(\.\d+)?$/.test(deductExpenses))) {
                    return
                }

                let params = {};
                params = { ...this.$refs.addEditDetails.$refs.assignForm_list.formData, ...this.$refs.addEditDetails.$refs.assignForm_reference.formData };
                params.id = this.pageProp.id ? this.pageProp.id : null;
                this.$refs.addEditDetails.contarctList.forEach(item => {
                    if(params.saleContractCode != ''){
                        if (item.code == params.saleContractCode) {
                            params.prjId = item.prjId;
                            params.saleContractId = item.id
                        }
                    }else{
                            params.prjId = '';
                            params.saleContractId = ''
                    }
                    
                })
                //应结算金额
                if(params.relateType == '0'){
                    params.settleAmount = 0
                }else if((params.relateType == '1')){
                    params.settleAmount = ((params.procurementInitialAmount || 0 * 1) * (params.saleContractRefundRatio || 0 * 1)).toFixed(2)
                }
                
                postApi(`/contract/selfestablished/procurement/save/${type}`, params).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.AddEditVisible = false;
                    this.getTableData()
                })
            }
            else {
                let flag = false;
                this.$refs.addEditDetails.$refs.assignForm_remark.$refs.assignForm_remark.validate((valid) => {
                    if (valid) {
                        flag = true
                    }
                })
                if (!flag) return false;
                if (!this.$refs.addEditDetails.$refs.assignForm_list.formData.procurementFinallyAmount) {
                    this.$message.warning('请输入采购合同变更后总金额')
                    return false;
                }
                let params = {};
                params = {
                    ...this.$refs.addEditDetails.$refs.assignForm_remark.formData,
                    id: this.$refs.addEditDetails.$refs.assignForm_list.formData.id,
                    procurementFinallyAmount: this.$refs.addEditDetails.$refs.assignForm_list.formData.procurementFinallyAmount,
                }

                //应结算金额
                if(this.$refs.addEditDetails.$refs.assignForm_list.formData.relateType == '0'){
                    params.settleAmount = 0
                }else if((this.$refs.addEditDetails.$refs.assignForm_list.formData.relateType == '1')){
                    params.settleAmount = ((this.$refs.addEditDetails.$refs.assignForm_list.formData.procurementFinallyAmount || 0 * 1) 
                    * (this.$refs.addEditDetails.$refs.assignForm_reference.formData.saleContractRefundRatio || 0 * 1)).toFixed(2)
                }
                
                putApi(`/contract/selfestablished/procurement`, params).then(res => {
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.AddEditVisible = false;
                    this.getTableData()
                })
            }

            this.selectData = [];
            this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, { code: "PC-ZJCG-STATUS" }).then(res => {
                if (res.data.code == 0) {
                    this.formList.forEach(item => {
                        if (item.value == 'status') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
        },
      clearTableSelection() {
        this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
      },
    }
}
</script>

<style lang="less" scoped></style>