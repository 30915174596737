<template>
  <div class="container">
    <dd-card title="合同信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'230px'"
        :flex="3"
        ref="assignForm_list"
        formRef="assignForm_list"
      ></dd-form>
    </dd-card>
    <dd-card title="参考信息">
      <dd-form
        :form-config="formList_reference"
        :labelWidth="'230px'"
        :flex="3"
        ref="assignForm_reference"
        formRef="assignForm_reference"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";

export default {
  name: "saleMaintenance",
  props: ["salePageProp"],
  data() {
    return {
      formList: [
        {
          label: "合同编号",
          value: "fnumber",
          inputType: "text",
          disabled: true,
        },
        {
          label: "合同名称",
          value: "fname",
          inputType: "text",
          disabled: true,
        },
        {
          label: "合同类型",
          value: "procurementType",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "code",

          },
          rules: [
            {
              required: true,
              message: "请选择合同类型",
              trigger: "blur",
            },
          ],
          placeholderText:
              "请选择合同类型进行搜索",
        },
        {
          label: "变更后总金额",
          value: "ftotalamount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "甲方单位",
          value: "fpartaName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "乙方单位",
          value: "fpartbName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "是否关联销售合同",
          value: "relateType",
          inputType: "select",
          optionsList: [
            { code: "1", name: "是" },
            { code: "0", name: "否" },
          ],
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          clearable: false,
          rules: [
            {
              required: true,
              message: "请选择关联销售合同类型",
              trigger: "blur",
            },
          ],
          placeholderText: "请选择关联销售合同类型",
          change: this.relateTypeSelect,
        },
        {
          label: "关联销售合同名称",
          value: "saleContractName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择关联销售合同名称",
              trigger: "blur",
            },
          ],
          placeholderText: "请选择关联销售合同名称",
          remote: true,
          change: ($event, value) => {
            this.changesaleContract($event, value, "name");
          },
          remoteMethod: (query) => {
            this.queryContarctList({ name: query });
          },
        },
        {
          label: "关联销售合同编号",
          value: "saleContractCode",
          inputType: "select",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "code",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择关联销售合同编号",
              trigger: "blur",
            },
          ],
          change: ($event, value) => {
            this.changesaleContract($event, value, "code");
          },
          remote: true,
          remoteMethod: (query) => {
            this.queryContarctList({ code: query });
          },
          placeholderText: "请选择关联销售合同编号",
        },
        {
          label: "关联项目名称",
          value: "prjName",
          disabled: true,
          inputType: "text",
        },
        {
          label: "关联项目编号",
          value: "prjCode",
          disabled: true,
          inputType: "text",
        },
        {
          label: "备注",
          placeholderText: "请输入备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
        },
        {
          label: "应结算金额",
          value: "settleAmount",
          inputType: "text",
          placeholderText: "请输入应结算金额",
          rules: [
            {
              required: true,
              message: "请输入应结算金额",
              trigger: "blur",
            },
            {
              pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
      ],

      formList_reference: [
        {
          label: "销售合同价格形式",
          value: "cfnature",
          inputType: "text",
          disabled: true,
          placeholderText: "关联销售合同",
        },
        {
          label: "销售合同额",
          value: "saleContractAmount",
          inputType: "text",
          disabled: true,
          placeholderText: "关联销售合同",
        },
        {
          label: "销售合同结算金额",
          value: "saleContractSettleAmount",
          inputType: "text",
          disabled: true,
          placeholderText: "关联销售合同",
        },
        {
          label: "销售回款",
          value: "saleContractRefund",
          inputType: "text",
          disabled: true,
          placeholderText: "关联销售合同",
        },

        {
          label: "销售回款比例",
          value: "saleContractRefundRatio",
          inputType: "text",
          disabled: true,
          placeholderText: "关联销售合同",
        },
      ],
      contarctList: [],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    // setTimeout((res) => {
    //   this.getPageData();
    // }, 500);

    this.getPageData();
    this.queryContarctList();
  },
  methods: {
    getDataType() {
      // 采购合同类型
      postApi(`/contract/dicttype/list/all`, { code: "PC-CCONTRACTTYPE-STATUS" }).then(res => {
        if (res.data.code == 0) {
          this.formList.forEach(item => {
            if (item.value == 'procurementType') {
              item.optionsList = res.data.data
            }
          })
        }
      })
    },
    getPageData() {
      getApi(
        `/contract/contractpurchaseinfo/query/sale?id=${this.salePageProp.purchaseInfoId}`
      ).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.settleAmount || res.data.data.settleAmount == 0) {
            res.data.data.settleAmount = res.data.data.settleAmount.toString();
          }

          this.initFormData(res.data.data);
          this.relateTypeSelect(res.data.data.relateType);
        }
      });
    },
    initFormData(data) {
      const keysToReference = [
        "saleContractAmount",
        "cfnature",
        "saleContractRefund",
        "saleContractRefundRatio",
        "saleContractSettleAmount",
      ];
      this.$refs.assignForm_reference.formData = Object.keys(data).reduce(
        (acc, key) => {
          if (keysToReference.includes(key)) {
            acc[key] = data[key];
          }
          return acc;
        },
        {}
      );
      this.$refs.assignForm_list.formData = Object.keys(data).reduce(
        (acc, key) => {
          if (!keysToReference.includes(key)) {
            acc[key] = data[key];
          }
          return acc;
        },
        {}
      );
    },
    queryContarctList(obj) {
      // 销售合同
      postApi(`/project/contractinfo/query`, obj).then((res) => {
        if (res.data.code == 0) {
          this.contarctList = res.data.data;
          this.formList.forEach((item) => {
            if (item.value == "saleContractName") {
              item.optionsList = res.data.data;
            }
            if (item.value == "saleContractCode") {
              item.optionsList = res.data.data;
            }
          });
        }
      });
    },
    changesaleContract($event, value, key) {
      if ($event) {
        this.contarctList.forEach((item) => {
          if (item[key] == $event) {
            if (key == "name") {
              this.$refs.assignForm_list.formData.saleContractCode = item.code;
            } else {
              this.$refs.assignForm_list.formData.saleContractName = item.name;
            }
            this.$refs.assignForm_list.formData.prjName = item.prjName;
            this.$refs.assignForm_list.formData.prjCode = item.prjCode;
            this.$refs.assignForm_reference.formData.cfnature = item.cfnature;
            this.$refs.assignForm_reference.formData.saleContractAmount =
              item.foriginalamount;
            this.$refs.assignForm_reference.formData.saleContractSettleAmount =
              item.ftotalamount;
            this.$refs.assignForm_reference.formData.saleContractRefund =
              item.frealamount;
            this.$refs.assignForm_reference.formData.saleContractRefundRatio =
              item.saleContractRefundRatio;
            this.$refs.assignForm_list.formData.settleAmount = (
              (this.salePageProp.ftotalamount || 0 * 1) *
              (this.$refs.assignForm_reference.formData
                .saleContractRefundRatio || 0 * 1)
            ).toFixed(2);
            this.$forceUpdate();
          }
        });
      } else {
        this.$refs.assignForm_list.formData.saleContractCode = "";
        this.$refs.assignForm_list.formData.saleContractName = "";
        this.$refs.assignForm_list.formData.prjName = "";
        this.$refs.assignForm_list.formData.prjCode = "";
        this.$refs.assignForm_reference.formData.saleContractAmount = null;
        this.$refs.assignForm_reference.formData.saleContractSettleAmount =
          null;
        this.$refs.assignForm_reference.formData.saleContractRefund = null;
        this.$refs.assignForm_reference.formData.saleContractRefundRatio = null;
        this.$refs.assignForm_reference.formData.cfnature = null;
      }
    },
    relateTypeSelect(code) {
      this.formList.forEach((item) => {
        if (item.value == "relateType") {
          item.defaultValue = code;
        }
        if (code == "0") {
          if (
            item.value == "saleContractName" ||
            item.value == "saleContractCode" ||
            item.value == "prjCode" ||
            item.value == "prjName"
          ) {
            item.defaultValue = "";
          } else {
            item.defaultValue = this.$refs.assignForm_list.formData[item.value];
          }
          if (
            item.value == "saleContractName" ||
            item.value == "saleContractCode"
          ) {
            item.disabled = true;
            item.rules[0].required = false;
          }
          if (item.value == "settleAmount") {
            item.disabled = false;
          }
        } else if (code == "1") {
          item.defaultValue = this.$refs.assignForm_list.formData[item.value];
          if (
            item.value == "saleContractName" ||
            item.value == "saleContractCode"
          ) {
            item.disabled = false;
            item.rules[0].required = true;
          }
          if (item.value == "settleAmount") {
            item.disabled = true;
          }
        }
      });
      this.formList_reference.forEach((item) => {
        if (code == "0") {
          this.$refs.assignForm_reference.formData[item.value] = null;
        } else if (code == "1") {
          item.defaultValue =
            this.$refs.assignForm_reference.formData[item.value];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>