<template>
    <div class="container">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="结算关联" name="结算关联">
            <settlementAssociation ref="settlementAssociation" :pageProp="pageProp"></settlementAssociation>
        </el-tab-pane>
        <el-tab-pane label="付款关联" name="付款关联">
            <paymentAssociation ref="paymentAssociation" :pageProp="pageProp"></paymentAssociation>
        </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import settlementAssociation from './settlementAssociation.vue';
import paymentAssociation from './paymentAssociation.vue';
export default {
  name: "maintenance",
  props: ["pageProp"],
  components: {settlementAssociation,paymentAssociation},
  data() {
    return {
      activeName: "结算关联",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(){
        if(this.activeName == '结算关联'){
          this.$refs.settlementAssociation.getTableList();
        }else if(this.activeName == '付款关联'){
          this.$refs.paymentAssociation.getTableList();
        }
    }
  }
};
</script>

<style>
</style>